import React, {CSSProperties, FC, memo, useLayoutEffect, useRef} from "react";
import {DifferenceContextMenu} from "../DifferenceContextMenu/DifferenceContextMenu";
import {getStoreUtils} from "../../store/utils";
import {setDifferenceStatus} from "../../ducks/montitoring";
import {CommentInput, CommentInputIdPrefix} from "../DifferenceListSidebar/CommentInput";
import {useIsPublic} from "../../hooks/useIsPublic";
import {useSelector} from "react-redux";
import { selectIsChangingView} from "../../selectors/monitoring";
import stylesList from "./DifferenceDocument.module.scss";
import styles from "./DifferenceDocument.module.scss";

type Props = {
    indexFromApi: number;
    id: string;
    styles: CSSProperties;
    classNameString: string;
    onMouseDown: (event: React.MouseEvent) => void;
    setIsChangingDetailedDiff: (flag: boolean) => void;
    isImportant: boolean;
    comment: string;
    detailedDiff: string;
    colorPrefix: string;
}

const Difference: FC<Props> = ({styles, id, colorPrefix, classNameString, onMouseDown, isImportant, indexFromApi, comment, setIsChangingDetailedDiff, detailedDiff}) => {
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const isPublic = useIsPublic();
    const docRef = useRef<HTMLDivElement>(null);
    const isChangingView = useSelector(selectIsChangingView);
    const {dispatch} = getStoreUtils();

    useLayoutEffect(() => {
        try {
            if (+detailedDiff === indexFromApi  && !isChangingView) {
                // props.setIsChangingDetailedDiff(true);
                docRef?.current?.scrollIntoView({inline: 'center', block: 'center'});
                setTimeout(() => {
                    docRef?.current?.classList?.add(`${stylesList[`${colorPrefix}Detailed`]}`)
                }, 10)
                setIsChangingDetailedDiff(false);
            }
        } catch (e) {
            console.log("error", e)
        }

    }, [detailedDiff, isChangingView]);
    const onClose = () => {
        setContextMenu(null);
    };
    const onSetCommentHandler = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        const element = document.getElementById(`${CommentInputIdPrefix}${indexFromApi}`);
        setTimeout(() => {
            element.click();
            element.focus();
        }, 10);
        onClose();
    };
    const onSetIsImportantHandler = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(setDifferenceStatus(indexFromApi, {
            important: !isImportant,
        }));
        onClose();
    };
    const onContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 5,
                    mouseY: event.clientY + 15,

                }
                : null,
        );
    }

    return <>
        <div
            ref={docRef}
            key={`${id}-difference-element`}
            onContextMenu={onContextMenu}
            id={id}
            onMouseDown={onMouseDown}
            style={styles}
            className={classNameString}
        />
        {!isPublic && <DifferenceContextMenu
            isImportant={isImportant}
            setImportantHandler={onSetIsImportantHandler}
            setCommentHandler={onSetCommentHandler}
            onClose={onClose}
            open={(contextMenu !== null)}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                    : undefined
            }
            commentInput={<CommentInput
                label={'Комментарий'}
                indexFromApi={indexFromApi}
                comment={comment}
                detailedDiff={detailedDiff}
                maxRows={2}
            />}
        />}
    </>
}
export const DifferenceComponent = memo(Difference);
