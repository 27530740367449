import React, {FC, useEffect, useRef, useState} from "react";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {TextField} from "@mui/material";
import {getStoreUtils} from "../../store/utils";
import {setDifferenceComment} from "../../ducks/montitoring";

export type CommentInputProps = {
    detailedDiff: string;
    comment: string;
    indexFromApi: number;
    label?: string;
    placeholder?: string;
    minRows?: number;
    maxRows?: number;
    disabled?: boolean;
}

export const CommentInputIdPrefix = 'comment-'
export const CommentInput: FC<CommentInputProps> = ({ comment, disabled, maxRows, detailedDiff, indexFromApi, label = "Комментарий", placeholder, minRows}) => {
    const ref = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>(comment);

    useEffect(() => {
        setValue(comment);
    }, [comment]);

    const handleCommentChange: TextFieldProps['onChange'] = (event) => {
        setValue(event?.target?.value ?? '');
    }

    return <TextField
        inputRef={ref}
        disabled={disabled}
        minRows={minRows}
        maxRows={maxRows}
        value={value}
        onKeyDown={(event) => {
            const isTab = event.key === 'Tab';
            if(isTab) {
                ref?.current?.blur()
            }
        }}
        onChange={handleCommentChange}
        fullWidth
        label={label}
        placeholder={placeholder}
        multiline
        size="small"
        InputLabelProps={{shrink: true}}
        sx={{fontSize: '12px'}}
        margin={"normal"}
        tabIndex={-1}
        id={`${CommentInputIdPrefix}${indexFromApi}`}
        onBlur={() => {
            getStoreUtils().dispatch(setDifferenceComment(detailedDiff, value));
        }}
    />
}
