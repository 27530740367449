import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {DifferenceDocument, PageParts} from "../../components/differenceDocument/DifferenceDocument";
import PageWrapper from "../../components/pageWrapper/PageWrapper";
import styles from './DifferencePage.module.scss';
import {IDifferenceData, IDifferencePart, IDiscrepancyStatus, IDocumentImage,} from "../../api/monitoringApi";
import {useWindowDimensions} from "../../components/windowDimensions/windowDimensions";
import {Button, CircularProgress, IconButton,} from "@mui/material";
import {useResizable} from "react-resizable-layout";
import {SampleSplitter} from "../../components/splitter/splitter";
import {DifferenceBarPosition, FirstDocumentPosition} from "../../components/navigationBar/navigationBar";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {DifferenceListSidebarConnected} from "../../components/DifferenceListSidebar/DifferenceListSidebarConnected";
import {IMonitoringState, ITechData, setDetailedDiff} from "../../ducks/montitoring";
import {DifferencePopupComment} from "../../components/DifferencePopupComment/DifferencePopupComment";
import {DifferenceLink} from "../../components/DifferenceLinkPopup/DifferenceLink";
import useKeyboardEvent from "../../hooks/use-keyboard-event";
import {diff_id_symbol} from "../../components/DifferenceListSidebar/DifferenceListSidebar";
import {CommentInputIdPrefix} from "../../components/DifferenceListSidebar/CommentInput";
import {getStoreUtils} from "../../store/utils";

export type FilesData = {
    firstDocumentName: string;
    secondDocumentName: string;
};

export interface IDifferencePageStateProps {
    differenceData: IDifferenceData[];
    detailedDiff: string | undefined;
    scrollSync: boolean;
    menuOpen: boolean;
    isPageLoading: IMonitoringState['pageLoadingState'];
    isChangingDetAiledDiff: boolean;
    isChangingView: boolean;
    isMagnifierActive: boolean;
    panelPosition: string;
    docPosition: string;
    urlId: any;
    leftScale: number;
    zoomCount: number;
    docFilterList: string[];
    rightScale: number;
    barWidth: number;
    imagesLeft: IDocumentImage[];
    imagesRight: IDocumentImage[];
    techData: ITechData;
    report: string;
    detailedDocLeftId: string;
    filteredDiscrepancyIndexes: number[];
    comment: string;
    filesData: FilesData;
}

export interface IDifferencePageDispatchProps {
    setDetailedDiff: (diff: string | undefined) => void;
    onClearDifferencePage: () => void;
    setDifferenceStatus: (index: number, status: IDiscrepancyStatus) => void;
    deleteUnrecognizedPackagesImages: (packageID: number) => void;
    setLeftScale: (leftScale: number) => void;
    setRightScale: (rightScale: number) => void;
    setIsTrackDisabled: (flag: boolean) => void;
    setIsChangingDetailedDiff: (flag: boolean) => void;
    setMenuOpen: (flag: boolean) => void;
}

export type DifferencePageProps = IDifferencePageStateProps & IDifferencePageDispatchProps;

export interface IDifferenceDocumentPageState {
    zoomLeft: number,
    zoomRight: number,
    rotateLeft: Rotate,
    rotateRight: Rotate,
    mouseRight: boolean,
    mouseLeft: boolean,
    comment: string,
}

type Rotate = Record<number, number>;

const initialState: IDifferenceDocumentPageState = {
    zoomLeft: 100,
    zoomRight: 1,
    rotateLeft: {},
    rotateRight: {},
    mouseRight: false,
    mouseLeft: false,
    comment: '',
}

export const cn = (...args: any[]) => args.filter(Boolean).join(" ");

export interface SplitterContainerProps {
    max: number;
    initial: number;
    min: number;
    setPosition: (value: number) => void;
    axis: 'x' | 'y';
    reverse: boolean;
    containerRef: React.RefObject<HTMLDivElement>;
    dir?: 'horizontal' | undefined;
}

const SplitterContainer = (props: SplitterContainerProps) => {
    const {
        isDragging: isTerminalDragging,
        position: terminalH,
        splitterProps: terminalDragBarProps
    } = useResizable({
        containerRef: props.containerRef,
        max: props.max,
        axis: props.axis,
        initial: props.initial,
        min: props.min,
        reverse: props.reverse,
    });
    useEffect(() => {
        props.setPosition(terminalH);
    }, [terminalH])

    return <SampleSplitter
        dir={props.dir}
        isDragging={isTerminalDragging}
        {...terminalDragBarProps}
    />
}

export function DifferencePage(props: DifferencePageProps) {
    const {barWidth, filesData} = props;
    const pageContainerRef: React.RefObject<HTMLDivElement> = useRef();
    const leftScrollContainer: React.RefObject<HTMLDivElement> = useRef();
    const leftPartRef: React.RefObject<HTMLDivElement> = useRef();
    const rightPartRef: React.RefObject<HTMLDivElement> = useRef();
    const rightScrollContainer: React.RefObject<HTMLDivElement> = useRef();
    const [state, setState] = useState(structuredClone(initialState));
    const [topScroll, setTopScroll] = useState(0);
    const [verticalHeight, setVerticalHeight] = useState((window.innerHeight - 75) / 2);
    const [horizontalHeight, setHorizontalHeight] = useState((window.innerWidth - barWidth - 12 - 2.5) / 2);
    const [, setBottomScroll] = useState(0);
    const isPanelLeft = props.panelPosition === DifferenceBarPosition.LEFT || !props.panelPosition;
    const isDocOnTop = props.docPosition === FirstDocumentPosition.TOP || !props.docPosition;
    const [displayMagnifier, setDisplayMagnifier] = useState(true);
    const {differenceData, filteredDiscrepancyIndexes, detailedDiff, menuOpen, docFilterList} = props;
    const {dispatch} = getStoreUtils();

    useLayoutEffect(() => {
       if(!detailedDiff) {
           const findIndex = differenceData?.findIndex(el => !filteredDiscrepancyIndexes.includes(el.indexFromApi));
           if(findIndex >= 0) {
               dispatch(setDetailedDiff(findIndex.toString()));
           }
       }
    }, [detailedDiff, differenceData.length, filteredDiscrepancyIndexes.length]);

    const sortedArray = useMemo(() => {
        return differenceData?.sort((a,b) => a.indexFromApi - b.indexFromApi)
    }, [differenceData]);

    const recursiveDetailedIndex = (index: number, direction: 'forward' | 'back') => {
        const counter = direction === 'forward' ? 1 : -1;
        const newElement = sortedArray[index + counter];

        if(!newElement) return filteredDiscrepancyIndexes.includes(index) ? Number(detailedDiff) : index;
        if(newElement && !filteredDiscrepancyIndexes.includes(Number(newElement.indexFromApi))) {
            return newElement.indexFromApi;
        }
        return recursiveDetailedIndex(index + counter, direction);
    };

    const onTab = (event, index, indexFromApi) => {
        if(event.keyCode === 9) {
            let nextDiffId;
            if (event.shiftKey) {
                nextDiffId = recursiveDetailedIndex(indexFromApi, 'back')
            } else {
                nextDiffId = recursiveDetailedIndex(indexFromApi, 'forward')
            }
            onSetDetailedDiff(String(nextDiffId))

        }
    };

    const onDocumentTab = useCallback((e: KeyboardEvent) => {
        const {differenceData, detailedDiff} = props;
        const isTab = e.key === 'Tab';
        if(isTab && !detailedDiff){
            const id = differenceData[0]?.indexFromApi?.toString();
            const diff = document.getElementById(`${diff_id_symbol}${id}`)
            setTimeout(() => {
                props.setDetailedDiff(id);
                diff?.focus();
            }, 10);
        }
        if(detailedDiff){
            const findIndex = differenceData?.findIndex(el => el.indexFromApi === +detailedDiff);
            const targetEl = e.target as HTMLElement;
            if(findIndex >= 0 && e.keyCode === 32 && targetEl?.id?.includes(diff_id_symbol)) {
                e.stopPropagation();
                e.preventDefault();
                props.setDifferenceStatus(findIndex, {
                    important: !differenceData[findIndex]?.important
                });
            }
            if(e.keyCode == 13) {
                e.preventDefault();
                document.getElementById(`${CommentInputIdPrefix}${detailedDiff}`).focus();
            }
            if(isTab) {
                e.stopPropagation();
                e.preventDefault();
                onTab(e, findIndex, +detailedDiff);
            }
        }

    }, [props.differenceData.length, props.detailedDiff]);
    useKeyboardEvent(onDocumentTab);

    useEffect(() => {
        if (props.docPosition === FirstDocumentPosition.TOP) {
            setVerticalHeight((window.innerHeight - 75) / 2);
        } else {
            setHorizontalHeight((window.innerWidth - barWidth - 12 - 2.5) / 2);
        }
    }, [props.panelPosition, props.docPosition, props.menuOpen])

    useWindowDimensions();

    useLayoutEffect(() => {
        return () => {
            props.onClearDifferencePage();
        }
    }, []);

    const onSetDetailedDiff = (diff: string | undefined) => {
        if (diff) {
            props.setDetailedDiff(diff);
        }
    }

    const leftData: IDifferencePart[][] = useMemo(() => {
        return props.imagesLeft.map((diff, index) => {
            let newDiffs: IDifferencePart[] = [];

            if (props.differenceData.length > 0) {
                props.differenceData
                    .filter(elem => elem.locationsOnFirstDocument && elem.locationsOnFirstDocument.length > 0)
                    .forEach(elem => {
                        elem.locationsOnFirstDocument.forEach(location => {
                            if (location.imageId === diff.imageId && elem.important) {
                                newDiffs.push({
                                    ...location,
                                    discrepancyElements: elem.discrepancyElements,
                                    indexFromApi: elem.indexFromApi,
                                    type: elem.type,
                                    isImportant: elem.important,
                                    comment: elem.comment,
                                })
                            }

                        })
                    });
            }
            console.log("leftData PROSHLO", newDiffs, props.imagesLeft);

            return newDiffs;
        })
    }, [props.imagesLeft.length, props.differenceData.length, props.differenceData]);

    const rightData: IDifferencePart[][] = useMemo(() => {
        return props.imagesRight.map((diff, index) => {
            let newDiffs: IDifferencePart[] = [];

            if (props.differenceData.length > 0) {
                props.differenceData
                    .filter(elem => elem.locationsOnSecondDocument && elem.locationsOnSecondDocument.length > 0)
                    .forEach(elem => {
                        elem.locationsOnSecondDocument.forEach(location => {
                            if (location.imageId === diff.imageId && elem.important) {
                                newDiffs.push({
                                    ...location,
                                    discrepancyElements: elem.discrepancyElements,
                                    indexFromApi: elem.indexFromApi,
                                    type: elem.type,
                                    isImportant: elem.important,
                                    comment: elem.comment,
                                })
                            }
                        })
                    });
            }

            return newDiffs;
        })
    }, [props.imagesRight.length, props.differenceData, props.differenceData.length]);

    const proportional = true

    const onButtonSetRotate = (count: number, part: "rotateRight" | "rotateLeft", pageIndex: number) => {
        let rotate = 0;

        try {
            rotate = (state[part][pageIndex] ?? 0) + count;
        }
        catch {
            rotate = count;
        }

        if (Math.abs(rotate) >= 360) {
            rotate = (Math.abs(rotate) - 360) * (Math.abs(rotate) / rotate);
        }

        try {
            setState((prevState) => {
                const prevRotate = prevState[part];
                prevRotate[pageIndex] = rotate;
                return {...prevState, [part]: {...prevRotate}};
            });
        }
        catch (e) {
            console.log("error", e)
        }
    };

    return <PageWrapper>
        <DifferencePopupComment/>
        <DifferenceLink/>
        {props.isPageLoading && <div className={styles.pageLoading}>
            {props.isPageLoading?.isComparing && <>
                <div className={styles.pageLoadingLabel}>Выполняется сравнение документов.</div>
                <div className={styles.pageLoadingLabel}>Пожалуйста, подождите</div>
            </>}
            {props.isPageLoading?.isLoading && <div className={styles.pageLoadingLabel}>Выполняется загрузка изображений</div>}
            <CircularProgress/>
        </div>}
        {!props.isPageLoading &&
        <div
            ref={pageContainerRef}
            id={"PageContainerLeft"}
            className={`${styles.pageContainer} ${isPanelLeft ? styles.panelLeft : ""}`}
        >
            <div
                style={{
                    width: `calc(100% - ${barWidth}px)`
                }}
                ref={leftPartRef}
                className={`${styles.leftPart} ${!isDocOnTop && styles.docLeft} `}>
                <div
                    key={'Page1'}
                    className={styles.rowItemContainer}
                    style={{
                        height: `${isDocOnTop ? `calc(100% - ${verticalHeight}px)` : "100%"}`,
                        // @ts-ignore
                        width: `${isDocOnTop ? `100%` : `${horizontalHeight}px `}`,
                    }}
                >
                    <div className={styles.title}>
                        {`Эталон:`}&nbsp;&nbsp;{`${filesData?.firstDocumentName ?? ''}`}
                    </div>
                    <div
                        onScroll={(e) => {
                            setDisplayMagnifier(false)
                            setTopScroll(e.currentTarget.scrollTop)
                            if (props.scrollSync && !props.isChangingDetAiledDiff) {
                                const rate = (e.currentTarget.scrollTop - topScroll) / (e.currentTarget.scrollHeight + (e.currentTarget.scrollTop > e.currentTarget.clientHeight ? e.currentTarget.clientHeight : 0));
                                if (topScroll === 0) {
                                    rightScrollContainer.current.scrollTo({
                                        left: undefined,
                                        top: (rightScrollContainer.current.scrollTop + (proportional ? rate * rightScrollContainer.current.scrollHeight : 1))
                                    })
                                } else {
                                    rightScrollContainer.current.scrollTo({
                                        left: undefined,
                                        top: (rightScrollContainer.current.scrollTop + (proportional ? rate * rightScrollContainer.current.scrollHeight : 1))
                                    })
                                }
                            }
                        }}
                        ref={leftScrollContainer}
                        id={'documentsContainerLeft'}
                        className={`${styles.documentsContainer} ${props.rightScale < 1 ? styles.centering : ""}`}
                    >
                        {props.imagesLeft.length > 0 && props.imagesLeft.map((diff, index) => {

                            return <div key={`${index}-left`}>
                                {!diff.loading && <div className={styles.documentButtonsContainer}>
                                    <div>страница {index + 1} из {props.imagesLeft.length}</div>
                                    <IconButton
                                        title={'Повернуть влево на 90°'}
                                        onClick={() => {
                                            onButtonSetRotate(-90, "rotateLeft", index)
                                        }}
                                        className={styles.rotateLeftIcon}
                                    />
                                    <IconButton
                                        title={'Повернуть вправо на 90°'}
                                        onClick={() => {
                                            onButtonSetRotate(90, "rotateLeft", index)
                                        }}
                                        className={styles.rotateRightIcon}
                                    />
                                    <IconButton
                                        title={'Повернуть на 180°'}
                                        onClick={() => {
                                            onButtonSetRotate(180, "rotateLeft", index)
                                        }}
                                        className={styles.rotateRight180Icon}
                                    />
                                </div>}
                                    <DifferenceDocument
                                        key={`DifferenceDocument${index}-left`}
                                        setDisplayMagnifier={setDisplayMagnifier}
                                        displayMagnifier={displayMagnifier}
                                        docFilterList={props.docFilterList}
                                        isMagnifierActive={props.isMagnifierActive}
                                        isChangingView={props.isChangingView}
                                        fullHeight={!isDocOnTop}
                                        setIsChangingDetailedDiff={props.setIsChangingDetailedDiff}
                                        pagePartRef={leftPartRef}
                                        menuOpen={props.menuOpen}
                                        scale={props.leftScale}
                                        containerRef={leftScrollContainer}
                                        loading={diff.loading}
                                        setIsTrackDisabled={props.setIsTrackDisabled}
                                        setScale={props.setLeftScale}
                                        imageSrc={diff.fileUrl}
                                        rotate={state.rotateLeft[index]}
                                        zoom={props.zoomCount}
                                        docPosition={props.docPosition}
                                        items={leftData[index]}
                                        pageIndex={index}
                                        side={PageParts.Left}
                                        setDetailedDiff={onSetDetailedDiff}
                                        detailedDiff={props.detailedDiff}
                                        onLoad={() => {
                                            props.setLeftScale(1);
                                        }}
                                        setDifferenceStatus={props.setDifferenceStatus}
                                />
                            </div>
                        })}
                    </div>
                </div>
                {(isDocOnTop) && <SplitterContainer
                    dir={"horizontal"}
                    setPosition={setVerticalHeight}
                    reverse={true}
                    axis={'y'}
                    containerRef={leftPartRef}
                    max={750}
                    min={150}
                    initial={verticalHeight}
                />}

                {(!isDocOnTop) && <SplitterContainer
                    setPosition={setHorizontalHeight}
                    reverse={false}
                    axis={'x'}
                    containerRef={leftPartRef}
                    max={(window.innerWidth - barWidth - 12) * 0.75}
                    min={250}
                    initial={horizontalHeight}
                />}

                <div
                    ref={rightPartRef}
                    key={'Page2'}
                    className={styles.rowItemContainer}
                    style={{
                        //@ts-ignore
                        width: `${isDocOnTop ? `100%` : `calc(100% - ${horizontalHeight}px)`}`,
                        height: `${isDocOnTop ? `${verticalHeight}px` : "100%"}`,
                    }}
                >
                    <div className={styles.title}>
                        {`Измененный документ:`}&nbsp;&nbsp;{`${filesData?.secondDocumentName ?? ''}`}
                    </div>
                    <div
                        onScroll={(e) => {
                            setBottomScroll(e.currentTarget.scrollTop);
                            setDisplayMagnifier(false)
                        }}
                        ref={rightScrollContainer}
                        id={'documentsContainerRight'}
                        className={`${styles.documentsContainer} ${props.leftScale < 1 ? styles.centering : ""}`}
                    >
                        {props.imagesRight.length > 0 && props.imagesRight.map((diff, index) => {

                            return <div key={`${index}-right`}>
                                {!diff.loading && <div className={styles.documentButtonsContainer}>
                                    <div>страница {index + 1} из {props.imagesRight.length}</div>
                                        <IconButton
                                            title={'Повернуть влево на 90°'}
                                            onClick={() => {
                                                onButtonSetRotate(-90, "rotateRight", index)
                                            }}
                                            className={styles.rotateLeftIcon}
                                        />
                                        <IconButton
                                            title={'Повернуть вправо на 90°'}
                                            onClick={() => {
                                                onButtonSetRotate(90, "rotateRight", index)
                                            }}
                                            className={styles.rotateRightIcon}
                                        />
                                        <IconButton
                                            title={'Повернуть на 180°'}
                                            onClick={() => {
                                                onButtonSetRotate(180, "rotateRight", index)
                                            }}
                                            className={styles.rotateRight180Icon}
                                        />
                                    </div>}
                                    <DifferenceDocument
                                        key={`DifferenceDocument${index}-right`}
                                        setDisplayMagnifier={setDisplayMagnifier}
                                        displayMagnifier={displayMagnifier}
                                        docFilterList={props.docFilterList}
                                        isMagnifierActive={props.isMagnifierActive}
                                        isChangingView={props.isChangingView}
                                        fullHeight={!isDocOnTop}
                                        setIsChangingDetailedDiff={props.setIsChangingDetailedDiff}
                                        pagePartRef={rightPartRef}
                                        menuOpen={props.menuOpen}
                                        scale={props.rightScale}
                                        containerRef={rightScrollContainer}
                                        loading={diff.loading}
                                        setIsTrackDisabled={props.setIsTrackDisabled}
                                        setScale={props.setRightScale}
                                        imageSrc={diff.fileUrl}
                                        rotate={state.rotateRight[index]}
                                        zoom={props.zoomCount}
                                        docPosition={props.docPosition}
                                        pageIndex={index}
                                        items={rightData[index]}
                                        side={PageParts.Right}
                                        setDetailedDiff={onSetDetailedDiff}
                                        detailedDiff={props.detailedDiff}
                                        onLoad={() => {
                                            props.setRightScale(1);
                                        }}
                                        setDifferenceStatus={props.setDifferenceStatus}
                                    />
                                </div>
                            })}
                    </div>

                </div>

            </div>
            <div
                className={`${styles.rightPart}`}
                style={{
                    width: `${barWidth}px`
                }}
            >
                <DifferenceListSidebarConnected/>
                <Button

                    onClick={() => {
                        props.setMenuOpen(!props.menuOpen)
                    }}
                    className={`${styles.linkPdf} ${props.menuOpen ? styles.buttonFullWidth : styles.buttonShort}`}
                >
                    {props.menuOpen && <React.Fragment>
                        <div className={styles.hidePanelContainer}>
                            {!(props.panelPosition === DifferenceBarPosition.RIGHT) ?
                                <KeyboardDoubleArrowLeftIcon/> :
                                <KeyboardDoubleArrowLeftIcon
                                    className={styles.mirror}
                                />}
                        </div>
                    </React.Fragment>}
                    {!props.menuOpen && <React.Fragment>
                        <div className={styles.hidePanelContainer}>
                            {!(isPanelLeft) ? <KeyboardDoubleArrowLeftIcon/> : <KeyboardDoubleArrowLeftIcon
                                className={styles.mirror}
                            />}
                        </div>
                    </React.Fragment>}

                </Button>
            </div>
        </div>
        }
    </PageWrapper>
}
