import React, {FC, memo, useLayoutEffect, useRef} from "react";
import {IconButton, Paper} from "@mui/material";
import {CommentInput, CommentInputIdPrefix} from "./CommentInput";
import styles from "../../pages/DifferencePage/DifferencePage.module.scss";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {ThemeProvider} from "@mui/material/styles";
import {differenceSideBarTheme} from "./theme";
import Tooltip from "@mui/material/Tooltip";
import {DifferenceType, IDifferenceData} from "../../api/monitoringApi";
import {Icon} from "@iconify/react";
import deleteTextSvg from "../../pages/DifferencePage/delete_text.svg";
import {SignIcon, StampIcon} from "../icons";
import {DifferenceContextMenu} from "../DifferenceContextMenu/DifferenceContextMenu";
import {diff_id_symbol} from "./DifferenceListSidebar";
import {getDifferenceData} from "../../selectors/other";

type Props = {
    detailedDiff: string;
    diff: IDifferenceData;
    topIndex: number;
    onSetIsImportant: (e: React.MouseEvent) => void;
    onSetComment: (e: React.MouseEvent) => void;
    handleOnDiffClick: (e: React.MouseEvent) => void;
    handleOnContextMenu: (event: React.MouseEvent) => void;
    isCollapsed: boolean;
    isPublic: boolean;
    onClose: VoidFunction;
    contextMenu: {
        mouseX: number;
        mouseY: number;
        diffId: number;
    } | null;
};

const Element: FC<Props> =
    ({
         handleOnContextMenu,
         handleOnDiffClick,
         onSetIsImportant,
         onSetComment,
         diff,
         topIndex,
         detailedDiff,
         isCollapsed,
         isPublic,
         contextMenu,
         onClose
    }) => {
    const diffID = `${diff.indexFromApi}`;
        useLayoutEffect(() => {
            try {
                if (+detailedDiff === diff?.indexFromApi) {
                    docRef?.current?.scrollIntoView({inline: 'center', block: 'nearest'});
                    // docRef?.current?.focus();
                }
            } catch (e) {
                console.log("error", e)
            }

        }, [detailedDiff, diff?.indexFromApi]);

    const docRef = useRef<HTMLDivElement>(null);

    const diffData = getDifferenceData(diff.type);

    const beforeAfterText = <React.Fragment>
        <div
            className={`${styles.commentsContainerItemWrapperItem}`}>
            <div className={styles.text}> <span
                className={styles.bold}>До:</span> {diff.changedTextBefore}
            </div>
        </div>
        <div
            className={`${styles.commentsContainerItemWrapperItem}`}>
            <div className={styles.text}>
                                <span
                                    className={styles.bold
                                    }>
                                    После:
                                </span>
                {" " + diff.changedTextAfter}
            </div>
        </div>
    </React.Fragment>

    return <> <Paper
        ref={docRef}
        onKeyDown={(e) => {
            const targetEl = e.target as HTMLElement;
            if(e.keyCode == 32 && !targetEl?.id?.includes(CommentInputIdPrefix)) {
                e.preventDefault();
            }
        }}
        id={`${diff_id_symbol}${diffID}`}
        tabIndex={topIndex + 1}
        key={`${diffID}${topIndex}`}
        elevation={0}
        className={`${styles.commentsContainerItem}  ${(detailedDiff === diffID) && styles.selectedItem} ${isCollapsed ? styles.centerIcon : ""}`}
        onClick={handleOnDiffClick}
        onContextMenu={handleOnContextMenu}
    >
        <div
            className={`${styles.commentsContainerItemWrapper} ${isCollapsed ? styles.wrapperNoMargin : ""}`}
        >
            <div
                className={`${styles.commentsContainerItemWrapperItem} ${isCollapsed ? styles.wrapperNoMargin : ""} ${isCollapsed ? styles.centerIcon : ""} ${styles[diffData.color]}`}>
                {!isCollapsed && <div className={styles.nameRow}>
                                                <span>
                                                    {diffData.name}
                                                </span>
                    <IconButton
                        disabled={isPublic}
                        title={'Отметить как важное'}
                        className={`${(diff.important) ? styles.pushpinIconActive : ""}`}
                        sx={{
                            cursor: 'pointer'
                        }}
                        size={"small"}
                        onClick={onSetIsImportant}>
                        {diff.important ?
                            <StarIcon
                                className={styles.pushpinIcon}
                                fontSize="inherit"
                            /> :
                            <StarBorderIcon
                                className={styles.pushpinIcon}
                                fontSize="inherit"
                            />}
                    </IconButton>
                </div>}
                {isCollapsed && <React.Fragment
                >
                    <ThemeProvider theme={differenceSideBarTheme(`#F2F7FA`)}>
                        <Tooltip
                            sx={{
                                backgroundColor: `${diffData.colorValue} !important`,
                            }}
                            placement={'right'}
                            title={<React.Fragment>
                                <div
                                    className={`${styles.commentsContainerItemWrapperItem} ${styles[diffData.color]}`}>
                                    <span>{diffData.name}</span>
                                </div>
                                {(diff.type === DifferenceType.Added && diff.addedText) &&
                                    <div
                                        className={`${styles.commentsContainerItemWrapperItem}`}>
                                        <div className={styles.text}>{diff.addedText}</div>
                                    </div>}
                                {(diff.type === DifferenceType.Deleted && diff.deletedText) &&
                                    <div
                                        className={`${styles.commentsContainerItemWrapperItem}`}>
                                        <div className={styles.text}>{diff.deletedText}</div>
                                    </div>}
                                {(diff.type === DifferenceType.Changed && (diff.changedTextBefore && diff.changedTextAfter)) && beforeAfterText}
                            </React.Fragment>}
                        >
                            <div
                                style={{
                                    width: '80px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: `100%`,
                                }}
                            >
                                {diff.type === DifferenceType.Added &&
                                    <Icon icon="tabler:text-plus" width="32" height="32"/>}
                                {diff.type === DifferenceType.Deleted &&
                                    <img style={{objectFit: 'contain'}} width={32} height={32}
                                         src={deleteTextSvg} alt={''}/>}
                                {diff.type === DifferenceType.Changed &&
                                    <Icon icon="mdi:file-document-edit-outline"
                                          color={`${diffData.colorValue}`} width="32"
                                          height="32"/>}
                                {diff.type === DifferenceType.DeletedPages &&
                                    <Icon icon="mdi:file-document-remove-outline"
                                          color={`${diffData.colorValue}`} width="32"
                                          height="32"/>}
                                {diff.type === DifferenceType.AddedPages &&
                                    <Icon icon="mdi:file-document-plus-outline"
                                          color={`${diffData.colorValue}`} width="32"
                                          height="32"/>}
                                {diff.type === DifferenceType.ChangedPages &&
                                    <Icon icon="iconamoon:compare"
                                          color={`${diffData.colorValue}`} width="32"
                                          height="32"/>}
                                {diff.type === DifferenceType.AddedStamp && <StampIcon style={{padding: '4px'}} width={24} height={28} fill={diffData.colorValue}/>}
                                {diff.type === DifferenceType.DeletedStamp && <StampIcon style={{padding: '4px'}} width={24} height={28} fill={diffData.colorValue}/>}
                                {diff.type === DifferenceType.DeletedSign && <SignIcon style={{padding: '4px'}} width={24} height={28} fill={diffData.colorValue}/>}
                                {diff.type === DifferenceType.AddedSign && <SignIcon style={{padding: '4px'}} width={24} height={28} fill={diffData.colorValue}/>}
                                <IconButton
                                    disabled={isPublic}
                                    title={'Отметить как важное'}
                                    className={`${(diff.important)? styles.pushpinIconActive : ""}`}
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                    size={"small"}
                                    onClick={onSetIsImportant}>
                                    {diff.important ?
                                        <StarIcon
                                            className={styles.pushpinIcon}
                                            fontSize="inherit"
                                        /> :
                                        <StarBorderIcon
                                            className={styles.pushpinIcon}
                                            fontSize="inherit"
                                        />}
                                </IconButton>
                            </div>
                        </Tooltip>
                    </ThemeProvider>

                </React.Fragment>}
            </div>
            {!isCollapsed && <React.Fragment>
                {(diff.type === DifferenceType.Added && diff.addedText) && <div
                    className={`${styles.commentsContainerItemWrapperItem}`}>
                    <div className={styles.text}>{diff.addedText}</div>
                </div>}
                {(diff.type === DifferenceType.Deleted && diff.deletedText) && <div
                    className={`${styles.commentsContainerItemWrapperItem}`}>
                    <div className={styles.text}>{diff.deletedText}</div>
                </div>}
                {(diff.type === DifferenceType.Changed && (diff.changedTextBefore && diff.changedTextAfter)) && beforeAfterText}
                <div
                    className={`${styles.commentsContainerItemWrapperItem}`}
                >
                    <ThemeProvider theme={differenceSideBarTheme(`#F2F7FA`)}>
                        <CommentInput
                            disabled={isPublic}
                            indexFromApi={diff.indexFromApi}
                            comment={diff.comment}
                            detailedDiff={detailedDiff}
                        />
                    </ThemeProvider>
                </div>
            </React.Fragment>}
        </div>
    </Paper>
        {!isPublic && <DifferenceContextMenu
            onClose={onClose}
            open={(contextMenu !== null && contextMenu.diffId === diff.indexFromApi)}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                    : undefined
            }
            isImportant={diff.important}
            setImportantHandler={onSetIsImportant}
            setCommentHandler={onSetComment}
            commentInput={<CommentInput
                disabled={isPublic}
                indexFromApi={diff.indexFromApi}
                comment={diff.comment}
                detailedDiff={detailedDiff}
                maxRows={2}
            />}
        />}
    </>
}

export const DifferenceElement = memo(Element);
