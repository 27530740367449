import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {
    deleteUploads,
    getUploads,
    onClearUploads,
    sendPairToRecognition,
    setDetailedFilePages,
    setDetailedUpload,
    setSelectedIds,
    setShowPreviewPages,
    setUploadIsLoading,
    setUploadPageCount,
    setUploadPopupOpen,
    setUploads,
    uploadAndRecognizePair,
    uploadFiles,
} from "../../ducks/upload";
import {State} from "../../store/rootReducers";
import {
    selectDetailedFilePages,
    selectFirstDocumentFiles,
    selectFirstDocumentLoading,
    selectIdsOfSelectedFile,
    selectSecondDocumentFiles,
    selectSecondDocumentLoading,
    selectShowPreviewPages,
    selectUploadDetailedFile,
    selectUploadPopupIsBackground,
    selectUploadPopupIsOpen,
    selectUploadsIsLoading,
    selectUploadsPageCount
} from "../../selectors/upload";
import {popupPush} from "../../ducks/popup";
import {IUploadPopupDispatchProps, IUploadPopupStateProps, UploadPopup} from "./UploadPopup";
import {selectPath} from "../../selectors/other";
import {ROUTES} from "../../app/Routes";

const mapStateToProps: MapStateToProps<IUploadPopupStateProps, {}, State> = state => {
    let props: IUploadPopupStateProps = {
        firstDocumentFiles: [],
        secondDocumentFiles: [],
        detailedFile: undefined,
        pageCount: 0,
        isLoading: true,
        page: undefined,
        selectedIds: [],
        showPreviewPages: true,
        isOpen: false,
        isBackground: false,
        firstDocumentLoading: false,
        secondDocumentLoading: false,
        showClose: true,
    };

    const detailedFile = selectUploadDetailedFile(state);
    if (detailedFile) {
        props.detailedFile = {...detailedFile};
    }
    props.isLoading = selectUploadsIsLoading(state);
    props.firstDocumentFiles = selectFirstDocumentFiles(state);
    props.secondDocumentFiles = selectSecondDocumentFiles(state);
    props.pageCount = selectUploadsPageCount(state);
    props.page = selectDetailedFilePages(state);
    props.selectedIds = selectIdsOfSelectedFile(state);
    props.firstDocumentLoading = selectFirstDocumentLoading(state);
    props.secondDocumentLoading = selectSecondDocumentLoading(state);
    props.showPreviewPages = selectShowPreviewPages(state);
    //@ts-ignore
    const isMainPage = window.REACT_APP_NO_LIST;
    if(isMainPage){
        const path = selectPath(state, false);
        const isListPage = path === ROUTES.Monitoring || path === ROUTES.Home;
        const isOpen = selectUploadPopupIsOpen(state);

        props.isOpen = isListPage ? true : isOpen;
        props.isBackground = false;
        props.showClose = !isListPage;
    } else {
        props.isOpen = selectUploadPopupIsOpen(state);
        props.isBackground = selectUploadPopupIsBackground(state);
    }

    return {...props}
};

const dispatchProps: MapDispatchToProps<IUploadPopupDispatchProps, {}> = {
    setUploads,
    uploadFiles,
    setSelectedIds,
    setDetailedUpload,
    setUploadIsLoading,
    setUploadPageCount,
    setDetailedFilePages,
    popupPush,
    setShowPreviewPages,
    setUploadPopupOpen,
    onClearUploads,
    sendPackageToRecognition: sendPairToRecognition,
    getUploads,
    deleteUploads,
    uploadAndRecognizePair,
};

export const UploadPopupConnected = connect(
    mapStateToProps,
    dispatchProps,
)(UploadPopup);
