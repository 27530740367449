import {Button, IconButton, TextField} from "@mui/material";
import {setDifferenceUploadPopupState} from "../../ducks/montitoring";

import styles from "./DifferenceUpload.module.scss";
import React, {FC, useEffect} from "react";
import {useSelector} from "react-redux";
import {selectDifferenceUploadPopupState} from "../../selectors/monitoring";
import {getStoreUtils} from "../../store/utils";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {ROUTES} from "../../app/Routes";
import {styled} from "@mui/material/styles";
import {Link} from "react-router-dom";
import {push} from "connected-react-router";

const StyledCancelButton = styled(Button)`
  width: 170px;
  border-color: #F57B20;
  &:hover {
    border-color: #F57B20;
  };
  font-size: 15px;
  line-height: 15px;
  color: #000;
  height: 35px;
  white-space: nowrap;
  @media (max-width: 1600px) {
    font-size: 14px;
    line-height: 14px;
  }
`;
const StyledLinkButton = styled(StyledCancelButton)`
  background-color: #F57B20;
  &:hover {
    background-color: #F57B20;
  };
  color: #FFFFFF;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #FFFFFF;
`;
export const DifferenceUpload: FC = () => {
    const {dispatch} = getStoreUtils();
    const popupState = useSelector(selectDifferenceUploadPopupState);
    const isOpen = !!popupState;
    const link = `${window.location.origin}${process.env.REACT_APP_ROUTE_PREFIX ?? ''}${ROUTES.Doc}/${popupState?.link}`;

    const onCopy = () => {
        navigator?.clipboard?.writeText(link);
    };

    useEffect(() => {
        if(isOpen && popupState?.link) {
            onCopy();
        }
    }, [isOpen, popupState]);

    const onClose = () => {
        dispatch(setDifferenceUploadPopupState(undefined));
    };

    return isOpen? (
        <div
            className={styles.popupBack}>
            <div
                onClick={(e)=>{
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}
                className={styles.popupContainer}
            >
                <div className={styles.messageContainer}>
                    <div className={styles.row}>
                        <TextField
                            value={link}
                            fullWidth
                            size="small"
                            InputLabelProps={{shrink: true}}
                            sx={{fontSize: '12px'}}
                            margin={"normal"}
                        />
                        <IconButton
                            onClick={onCopy}
                        >
                            <ContentCopyIcon/>
                        </IconButton>
                    </div>
                    <div className={styles.row}>
                        <StyledCancelButton
                            onClick={()=>{
                                onClose();
                            }}
                            variant={'outlined'}
                        >
                            Загрузить новую
                        </StyledCancelButton>
                        <StyledLinkButton
                            onClick={() => {
                                setTimeout(() => {
                                    onClose();
                                },0);
                                dispatch(push(`${ROUTES.Doc}/${popupState?.link}`));
                            }}
                            variant={'contained'}
                        >
                            Открыть
                        </StyledLinkButton>
                    </div>
                </div>

            </div>
        </div>
    ) : (
        <></>
    );
}
