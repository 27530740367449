import React from "react";
import PageWrapper from "../../components/pageWrapper/PageWrapper";

export interface IAuthPageStateProps {
}

export interface IAuthPageDispatchProps {
}

type AuthProps = IAuthPageStateProps & IAuthPageDispatchProps;

export function AuthPage(props: AuthProps) {
    return <PageWrapper>
        <div></div>
    </PageWrapper>
}
